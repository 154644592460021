import Alpine from 'alpinejs';
import Controller from './controller';

class Theme extends Controller {
	data() {
		return {
			title: '樣式管理',
			theme: {},

			folder: {
				current: null,
				files: [],
				init() {
					const folder = Alpine.store('info').theme.folder;

					if (folder?.length > 0) {
						this.files = folder[0].file ?? [];
						this.current = folder[0]._id;
					}
				},
				changeTo(id) {
					this.current = +id;

					const folder = Alpine.store('info').theme.folder;
					this.files = folder.filter((folder) => +folder._id === +id)[0].file ?? [];
				},
			},

			file: {
				current: null,
				diff: null,
				content: '',
				diff: [],
			},

			all: this.all,
			sort: this.all,
			get: this.get,
			save: this.save,
			compose: this.compose,
			active: this.active,

			getFile: this.getFile,
			reset: this.reset,

			edit: this.edit,
			preview: this.preview,
			history: this.history,
		};
	}

	async all() {
		const query = Alpine.store('query');
		const result = await Alpine.store('api')
			.getClient()
			.post('theme/list', { query: query.fetch() });
		this.theme = result.data?.rows;

		const meta = {
			pos: +result.data.pos,
			count: +result.data.count,
			total: +result.data.total,
		};

		query.midify(meta);
		this.paginate.init(meta);
	}

	async get() {
		const info = Alpine.store('info');
		if (info.id == 0) {
			return false;
		}

		const result = await Alpine.store('api').getClient().post('theme/get', { id: info.id });
		this.theme = (({ id, name, slug, status, author, version, folder, is_public }) => ({
			id,
			name,
			slug,
			status,
			author,
			version,
			folder,
			is_public,
		}))(result.data);
		// this.plan.items = this.plan.item.map((i) => i._id);
		// delete this.plan.item;
		this.title += ' - ' + this.theme.name;

		this.folder.init();
	}

	async save() {
		let item = JSON.parse(JSON.stringify(this.theme));
		const payload = (({ id, name, is_public }) => ({ id, name, is_public }))(this.theme);
		payload.id = +this.id;

		const result = await Alpine.store('api').getClient().post('theme/save', payload);
		if ('code' in result && result.code === 1) {
			Alpine.store('router').go('theme');
			return;
		}
	}

	async getFile() {
		const result = await Alpine.store('api')
			.getClient()
			.post('theme/file', { id: this.file.current });
		if ('code' in result && result.code === 1) {
			if (result.data.diff) {
				const diff =
					result.data?.diff.filter((diff) => +diff._id === +this.file.diff_id)[0] ?? [];
				this.file.content = diff.content ?? '';
			}

			this.file.diff = result.data.diff ?? [];
			this.file.diff_id = result.data.diff_id;
			this.file.type = result.data.ext;
			this.file.name = result.data.slug;
			this.file.url = result.data.url;
			return;
		}
	}

	async compose() {
		const result = await Alpine.store('api').getClient().post('theme/compose', {
			id: this.file.current,
			content: this.file.content,
		});
		if ('code' in result && result.code === 1) {
			const modal = alpine.store('modal');
			modal.close();

			return false;
		}
	}

	async active(file_id, diff_id) {
		const result = await Alpine.store('api').getClient().post('theme/active', {
			id: +file_id,
			diff: +diff_id,
		});

		if ('code' in result && result.code === 1) {
			this.getFile();
		}
	}

	reset(file_id, diff_id) {
		this.file.current = +file_id;
		this.file.diff_id = +diff_id;
		this.file.content = '';
		this.file.diff = [];
		this.file.type = '';
		this.file.name = '';
		this.file.url = '';
	}

	edit(file_id, diff_id) {
		this.reset(file_id, diff_id);

		window.sendEvent('modal', {
			html: '/pages/theme/file-edit.html',
			title: '修改檔案',
			size: 'large',
		});
	}

	preview(file_id, diff_id) {
		this.reset(file_id, diff_id);

		window.sendEvent('modal', {
			html: '/pages/theme/file-preview.html',
			title: '預覽檔案',
			size: 'large',
		});
	}

	history(file_id, diff_id) {
		this.reset(file_id, diff_id);

		window.sendEvent('modal', {
			html: '/pages/theme/file-history.html',
			title: '檔案歷史',
		});
	}
}

export const theme = new Theme();
