export default function (Alpine) {
    Alpine.directive('dropzone', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const container = $(el);
        const input = container.find('input[type=file]');
        const loading = container.find('.dropzone__loading');

        input.off('dragover').off('dragleave').off('drop').off('change');

        input.on('dragover', function () {
            loading.toggleClass('is-active', false);
            container.toggleClass('is-active', true);
        });

        input.on('dragleave', function () {
            loading.toggleClass('is-active', false);
            container.toggleClass('is-active', false);
        });

        input.on('drop', function () {
            loading.toggleClass('is-active', false);
            container.toggleClass('is-active', false);
        });

        input.on('change', function (e) {
            const file = e.target.files;

            loading.toggleClass('is-active', true);

            if (file.length === 0) {
                loading.toggleClass('is-active', false);
                return false;
            }

            // const callback = evaluateLater(expression);
            evaluateLater(expression)(async (callback) => {
                await callback(file);

                loading.toggleClass('is-active', false);
            });
        });

        cleanup(() => {
            input.off('dragover').off('dragleave').off('drop').off('change');
        });
    });
}
