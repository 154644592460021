import Alpine from 'alpinejs';
import Controller from './controller';

// import { siteAvailableCheck, memberForgot, memberReset } from '../libs/api';
import { sleep, buttonFlow } from '../helpers/utils';

class Auth extends Controller {
    data() {
        return {
            title: 'Login',
            // login: this.login,
            // register: this.register,
            login: {
                data: {
                    account: '',
                    password: '',
                    token: '',
                },
                passwordType: 'password',
                saved: null,
                init() {
                    let saved = localStorage.getItem('rmbr');
                    saved = saved === null ? '{}' : saved;
                    saved = JSON.parse(saved);

                    const firstKey = Object.keys(saved)[0];

                    if (typeof firstKey !== 'undefined') {
                        const first = saved[firstKey];

                        this.saved = first;
                    }
                },
                loginByToken() {
                    Alpine.store('auth').signInByToken(
                        {
                            password: this.data.password,
                            token: this.data.token,
                            remember: this.saved.token,
                        },
                        'backend'
                    );
                },
                clearToken() {
                    this.saved = null;

                    localStorage.setItem('rmbr', '{}');
                },
                submit() {
                    Alpine.store('auth').signIn(this.data, 'backend');
                },
                async forgot(input, btn) {
                    btn = $(btn);

                    const button = buttonFlow(btn);

                    button.loading();

                    if (!$.trim(this.data.account) || !$.trim(this.data.token)) {
                        window.sendEvent('toast', { title: '我們需要你的信箱做密碼重設' });

                        input.focus();
                        button.reset();

                        return false;
                    }

                    await sleep(650);

                    const { code, data } = await memberForgot({ account: this.data.account, token: this.data.token });

                    button.done();
                    window.sendEvent('toast', { title: '已發送密碼提示到你的信箱', icon: 'bi-send' });

                    sendEvent('validation::reset');
                },
            },
        };
    }

    // login() {
    //     Alpine.store('auth').signIn(Alpine.store('info').formData);
    // }

    // register() {
    //     Alpine.store('auth').register(Alpine.store('info').register);
    // }
}

export const auth = new Auth();
