import { sleep } from '../helpers/utils';

export default function (Alpine) {
    Alpine.directive('textarea', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const ta = $(el);
        const model = ta.prop('x-model');
        const data = evaluateLater(expression);
        const withCounter = +ta.data('with-counter') === 1;

        const initial = false;
        const defHeight = `${+el.dataset.autoHeightValue || '36px'}`;
        const autoHeight = +el.dataset.autoHeight === 1;

        let setBuffer = null;
        let counter = null;

        const autoSize = () => {
            if (autoHeight === false) {
                return;
            }

            ta.height(defHeight);
            ta.height(`${ta.prop('scrollHeight')}px`);
        };

        const displayCount = (content) => {
            if (withCounter) {
                counter.html(`${content ?? ''}`.length);
            }
        };

        if (withCounter) {
            counter = $(`<span class="counter"></span>`);
            ta.wrap(`<div class='textarea--wrapper'></div>`);
            ta.parent().append(counter);
            ta.toggleClass('show-counter', true);
        }

        effect(() => {
            // console.log('textarea::effect', el);
            data((content) => {
                ta.val(`${content ?? ''}`);

                displayCount(content);
                autoSize();
            });
        });

        ta.on('input change', () => {
            // console.log('textarea::input::change', el);
            clearTimeout(setBuffer);
            setBuffer = setTimeout(() => {
                evaluate(`${expression} = '${ta.val()}'`);
                autoSize();
            }, 500);
        });

        cleanup(() => {
            ta.off('input change');
        });
    });
}
