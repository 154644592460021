import Alpine from 'alpinejs';
import Controller from './controller';

class Dashboard extends Controller {
    data() {
        return {
            title: '數據面板',
        };
    }
}

export const dashboard = new Dashboard();
