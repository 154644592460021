import feather from 'feather-icons';
import Alpine from 'alpinejs';
import { pick } from '../helpers/utils';

export default class Controller {
	isLoading = false;
	filterItems = [{ id: 1, name: '編號', type: 'int', col: 'id' }];

	data() {
		return {};
	}

	filter() {
		const query = Alpine.store('query');

		return {
			current: null,
			items: this.filterItems,
			init() {
				const settings = query.fetch();

				this.current = settings.query || {};
			},
			query(id, value) {
				let settings = query.fetch();

				console.log('filter::query::settings', settings);

				const item = this.items.find((item) => +item.id === +id) || null;
				console.log('query::item', item);
				if (item === null) {
					return false;
				}

				settings.query = settings.query ? settings.query : {};

				let toSave = pick(item, ['id', 'name', 'type', 'col']);
				settings.query[item.col] = { ...toSave, value, active: false };

				if (`${value}`.trim() !== '' && value !== 'all') {
					settings.query[item.col].active = true;
				} else {
					settings.query[item.col].active = false;
				}

				settings.pos = 0;

				query.update(settings);

				this.refresh();
			},

			active(col = null) {
				const current = this.current;

				if (col !== null) {
					return current[col]?.active === true;
				}

				console.log(
					'controller::filter::active',
					Object.values(current).every((i) => i.active === false)
				);

				return Object.values(current).every((i) => i.active === false);
			},

			// saved: query.fetch()['query'] ?? {},

			// usingIt(id) {
			// 	const item = this.list.find((site) => +site.id === +id);
			// 	this.current = { ...item, value: [''] };
			// },
			// query() {
			// 	if (this.current === null || this.current.value[0] === '') {
			// 		return false;
			// 	}

			// 	const query = Alpine.store('query');

			// 	let saved = query.fetch();
			// 	saved['query'] = typeof saved['query'] === 'undefined' ? {} : saved['query'];
			// 	saved['query'][this.current.col] = (({ id, name, col, type, value }) => ({
			// 		id,
			// 		name,
			// 		col,
			// 		type,
			// 		value,
			// 	}))(this.current);

			// 	this.saved[this.current.col] = saved['query'][this.current.col];

			// 	query.update(saved);

			// 	this.current = null;

			// 	this.refresh();
			// },
			// remove(col) {
			// 	const query = Alpine.store('query');

			// 	let saved = query.fetch();
			// 	if (typeof saved['query'][col] !== 'undefined') {
			// 		delete saved['query'][col];
			// 		delete this.saved[col];
			// 		query.update(saved);

			// 		this.refresh();
			// 	}
			// },
			refresh() {
				const settings = query.fetch();
				const info = Alpine.store('info');

				typeof info.list !== 'undefined' && info.list.get();
				this.current = settings.query || {};
			},
		};
	}

	paginate() {
		const query = Alpine.store('query');

		return {
			meta: {
				pos: 0,
				count: 1,
				total: 0,
			},
			changePos(pos) {
				this.pos = +pos;

				console.log('paginate::changePos', +pos);

				query.midify({ pos: this.pos });

				const info = Alpine.store('info');
				info.list.get();
			},
			init(meta) {
				if (!meta) {
					return false;
				}

				this.meta = meta;
			},
		};
	}

	preload(_$routeParams) {
		console.log('controller::preload', _$routeParams);

		Alpine.store('info', {
			...this.data(),
			filter: this.filter(),
			paginate: this.paginate(),
			..._$routeParams,
		});
	}

	run(_$routeParams) {}

	// getSort() {
	// 	const route = Alpine.store('router').current();
	// 	const key = `query_${route.hashString}`;

	// 	if (localStorage) {
	// 		let saved = localStorage.getItem(key);
	// 		saved = saved === null ? '{}' : saved;

	// 		saved = JSON.parse(saved);

	// 		return saved;
	// 	} else {
	// 		window.sendEvent('toast', {
	// 			title: `Error!!`,
	// 			message: 'localStorage not supported',
	// 		});
	// 	}

	// 	return {};
	// }
}
