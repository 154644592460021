// import Alpine from 'alpinejs';
import Navigo from 'navigo';

import { routeHandler, sleep } from '../helpers/utils';

import { app } from '../controllers/app';
import { auth } from '../controllers/auth';
import { role } from '../controllers/role';
import { site } from '../controllers/site';
import { staff } from '../controllers/staff';
// import { press } from '../controllers/press';
import { page } from '../controllers/page';
import { cate, file, editor } from '../controllers/file';
import { plan } from '../controllers/plan';
import { theme } from '../controllers/theme';
import { siteInit } from '../controllers/siteInit';
import { design } from '../controllers/design';
import { service } from '../controllers/service';
import { dashboard } from '../controllers/dashboard';
import { setting } from '../controllers/setting';
import { member } from '../controllers/member';
import { profile } from '../controllers/profile';

const router = new Navigo('/', { hash: true });

const appRoute = () => routeHandler(router, '/pages/base.html', '#wrapper', app);
const notFoundRoute = () => routeHandler(router, '/pages/notfound.html', '#wrapper', app);

const dashboardRoute = () => routeHandler(router, '/pages/dashboard.html', '#content', dashboard);

const authLoginRoute = () => routeHandler(router, '/pages/auth/login.html', '#wrapper', auth);
const authRegisterRoute = () => routeHandler(router, '/pages/auth/register.html', '#wrapper', auth);
const authLogoutRoute = () => routeHandler(router, '/pages/auth/logout.html', '#wrapper', auth);
const authForgotRoute = () => routeHandler(router, '/pages/auth/forgot.html', '#wrapper', auth);
const authForgotSuccessRoute = () => routeHandler(router, '/pages/auth/forgot-success.html', '#wrapper', auth);

const roleListRoute = () => routeHandler(router, '/pages/role/list.html', '#content', role);
const roleEditorRoute = (params) => routeHandler(router, '/pages/role/editor.html', '#content', role, params);

const siteListRoute = () => routeHandler(router, '/pages/site/list.html', '#content', site);
const siteEditorRoute = (params) => routeHandler(router, '/pages/site/editor.html', '#content', site, params);
const siteInitRoute = (params) => routeHandler(router, '/pages/site/init.html', '#wrapper', siteInit, params);

const staffListRoute = () => routeHandler(router, '/pages/staff/list.html', '#content', staff);
const staffEditorRoute = (params) => routeHandler(router, '/pages/staff/editor.html', '#content', staff, params);

const fileListRoute = (params) => routeHandler(router, '/pages/file/index.html', '#content', cate, params);
const filePanelRoute = (params) => {
	routeHandler(router, `/pages/file/cate-${params.type}.html`, '#right-panel', file, params);
};
const fileCreateRoute = (params) => routeHandler(router, '/pages/file/page-create.html', '#content', editor, params);

const pressListRoute = () => routeHandler(router, '/pages/press/list.html', '#content', press);
const pressEditorRoute = (params) => routeHandler(router, '/pages/press/editor.html', '#wrapper', press, params);
const pressPublishSettingRoute = (params) =>
	routeHandler(router, '/pages/press/publish-setting.html', '#wrapper', press, params);
const pressPublishSuccessRoute = (params) =>
	routeHandler(router, '/pages/press/publish-success.html', '#wrapper', press, params);
const pressImportRoute = () => routeHandler(router, '/pages/press/import.html', '#wrapper', press);

const pageListRoute = () => routeHandler(router, '/pages/page/list.html', '#content', page);
const pageEditorRoute = (params) => routeHandler(router, '/pages/page/editor.html', '#content', page, params);

const planListRoute = () => routeHandler(router, '/pages/plan/list.html', '#content', plan);
const planEditorRoute = (params) => routeHandler(router, '/pages/plan/editor.html', '#content', plan, params);

const themeListRoute = () => routeHandler(router, '/pages/theme/list.html', '#content', theme);
const themeEditorRoute = (params) => routeHandler(router, '/pages/theme/editor.html', '#content', theme, params);

const designListRoute = () => routeHandler(router, '/pages/design/config.html', '#content', design);
const serviceConfigRoute = () => routeHandler(router, '/pages/service/config.html', '#content', service);

const settingIndexRoute = () => routeHandler(router, '/pages/setting/index.html', '#content', setting);

const memberIndexRoute = () => routeHandler(router, '/pages/member/index.html', '#content', member);
const memberPreviewRoute = (params) => routeHandler(router, '/pages/member/preview.html', '#content', member, params);
const memberEditRoute = (params) => routeHandler(router, '/pages/member/edit.html', '#content', member, params);

const profileIndexRoute = () => routeHandler(router, '/pages/profile/index.html', '#content', profile);

export const initRouter = (alpine) => {
	const auth = alpine.store('auth');

	router.hooks({
		before: async (done, match) => {
			console.info('router::before', match);
			await auth.status(match);

			if (auth.isAuthenticated === false && auth.isWhitelisted(match) === false) {
				router.navigate('/auth/login', {
					updateBrowserURL: true,
					callHandler: true,
				});
				done(false);
				return;
			} else {
				// if (auth.isWhitelisted(match) === true) {
				// 	router.navigate('/', {
				// 		updateBrowserURL: true,
				// 		callHandler: true,
				// 	});
				// 	done(false);
				// 	return;
				// }
				done();
			}
		},
	});

	router.on(
		/press\/(?<id>\d+)$/,
		(args) => {
			pressEditorRoute(args.data);
		},
		{
			leave(done) {
				console.log('press::editor::leaving');
				window.sendEvent('press.editor.leaving', { done });
			},
		}
	);

	router.on({
		'/auth/login': () => {
			authLoginRoute();
		},
		'/auth/logout': () => {
			authLogoutRoute();
		},
		'/auth/register': () => {
			authRegisterRoute();
		},
		'/auth/forgot': () => {
			authForgotRoute();
		},
		'/auth/forgot/success': () => {
			authForgotSuccessRoute();
		},
		'/role': () => {
			appRoute().then(() => {
				roleListRoute();
			});
		},
		'/role/:id': (args) => {
			appRoute().then(() => {
				roleEditorRoute(args.data);
			});
		},
		'/init': () => {
			siteInitRoute();
		},
		'/site': () => {
			appRoute().then(() => {
				siteListRoute();
			});
		},
		'/site/:id': (args) => {
			appRoute().then(() => {
				siteEditorRoute(args.data);
			});
		},
		'/staff': () => {
			appRoute().then(() => {
				staffListRoute();
			});
		},
		'/staff/:id': (args) => {
			appRoute().then(() => {
				staffEditorRoute(args.data);
			});
		},
		'/file': () => {
			appRoute().then(() => {
				fileListRoute();
			});
		},
		'/file/:type': (args) => {
			appRoute().then(() => {
				fileListRoute(args.data).then(() => {
					filePanelRoute(args.data);
				});
			});
		},
		'/file/:type/:kid': (args) => {
			appRoute().then(() => {
				fileListRoute(args.data).then(() => {
					filePanelRoute(args.data);
				});
			});
		},
		'/file/:type/:id/:action': (args) => {
			appRoute().then(() => {
				fileCreateRoute(args.data);
			});
		},
		'/file/:type/:kid/:id/:action': (args) => {
			appRoute().then(() => {
				fileCreateRoute(args.data);
			});
		},
		'/press': () => {
			appRoute().then(() => {
				pressListRoute();
			});
		},
		'/page': () => {
			appRoute().then(() => {
				pageListRoute();
			});
		},
		'/page/:id': (args) => {
			appRoute().then(() => {
				pageEditorRoute(args.data);
			});
		},
		'/plan': () => {
			appRoute().then(() => {
				planListRoute();
			});
		},
		'/plan/:id': (args) => {
			appRoute().then(() => {
				planEditorRoute(args.data);
			});
		},
		'/theme': () => {
			appRoute().then(() => {
				themeListRoute();
			});
		},
		'/theme/:id': (args) => {
			appRoute().then(() => {
				themeEditorRoute(args.data);
			});
		},
		'/design': () => {
			appRoute().then(() => {
				designListRoute();
			});
		},
		'/service': () => {
			appRoute().then(() => {
				serviceConfigRoute();
			});
		},
		'/setting': () => {
			appRoute().then(() => {
				settingIndexRoute();
			});
		},
		'/member': () => {
			appRoute().then(() => {
				memberIndexRoute();
			});
		},
		'/member/:id': (args) => {
			appRoute().then(() => {
				memberPreviewRoute(args.data);
			});
		},
		'/member/:id/edit': (args) => {
			appRoute().then(() => {
				memberEditRoute(args.data);
			});
		},
		'/profile': () => {
			appRoute().then(() => {
				profileIndexRoute();
			});
		},
		'/': () => {
			appRoute().then(() => {
				console.log('router::/::dashboard');
				dashboardRoute();
			});
		},
	});

	router.notFound(() => {
		notFoundRoute();
	});

	alpine.store('router', {
		router: router,
		goback() {
			history.back();
		},
		go(url, options = {}) {
			options.updateBrowserURL = true;
			options.callHandler = true;
			options.callHooks = true;

			if (typeof options.silent !== 'undefined' && options.silent === true) {
				delete options.silent;
				options.callHandler = false;
				options.callHooks = false;
			}

			router.navigate(url, options);
		},
		refresh() {
			history.go(0);
		},
		current() {
			let current = router.getCurrentLocation();
			current.url = current.url.replace('#/', '');
			return current;
		},
		isCurrent(url) {
			const current = this.current();

			if (url === '' && current.hashString === '') {
				return true;
			} else if (url === '') {
				return false;
			}

			return current.hashString.includes(url);
		},
	});
	router.resolve();
};
