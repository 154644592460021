import { sleep } from './utils';
const templates = new Map();

export default {
    list: [],
    id: 0,
    tmpl: {},
    closeTa(id) {
        this.list = this.list.filter((modal) => modal.id !== +id);
    },
    closeThis(id) {
        this.closeTa(id);
    },
    add(data) {
        data.id = this.id;
        data.close = () => this.closeThis(data.id);
        this.id += 1;
        this.list.push(data);
    },
    close() {
        if (this.list.length === 0) {
            return false;
        }

        this.list.pop();
    },

    init(name, refs) {
        if (typeof name === 'undefined') {
            return false;
        }

        const content = this.get(name);
        if (content) {
            refs.innerHTML = '';
            sleep(50).then(() => {
                refs.innerHTML = content;
            });
        } else {
            fetch(name)
                .then((response) => response.text())
                .then((data) => {
                    refs.innerHTML = data;
                    this.save(name, data);
                });
        }
    },

    get(name) {
        return templates.get(name);
    },

    save(name, tmpl) {
        templates.set(name, tmpl);
    },
};
