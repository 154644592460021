import Alpine from 'alpinejs';
import Controller from './controller';

class Page extends Controller {
	filterItems = [{ id: 1, name: '編號', type: 'int', col: 'id' }];

	data(params) {
		return {
			title: '靜態頁面',
			currentLang: 'tw',
			page: {
				lang: [{ lang: 'tw' }, { lang: 'en' }],
				seo_desc: '',
			},
			list: [],
			async all() {
				const query = Alpine.store('query');

				const result = await Alpine.store('api')
					.getClient()
					.post('page/list', { query: query.fetch() });
				this.list = result.data?.rows;

				const meta = {
					pos: +result.data.pos,
					count: +result.data.count,
					total: +result.data.total,
				};

				query.midify(meta);
				this.paginate.init(meta);
			},
			async get() {
				const info = Alpine.store('info');

				if (info.id == 0) {
					return false;
				}

				const result = await Alpine.store('api')
					.getClient()
					.post('page/get', { id: info.id });
				this.page = {
					...result.data,
					seo_desc: '',
					publishment: {
						mode: '',
						queue: new Date().toISOString().slice(0, 16),
						status: '',
					},
				};
				this.title +=
					' - ' +
					this.page.lang
						.find((lang) => lang.lang === 'tw')
						?.title.replace(/(\r\n|\n|\r)/gm, '');
			},
			async save() {
				const info = Alpine.store('info');
				const result = await Alpine.store('api')
					.getClient()
					.post('page/save', { ...info.page });

				if (result.code && result.code === 1) {
					Alpine.store('router').go('page');

					return;
				}
			},
			sort: this.all,
		};
	}

	run(_$routeParams) {
		const auth = Alpine.store('auth');
		const router = Alpine.store('router');

		if (
			typeof auth.site.current === 'undefined' ||
			typeof auth.site.current.theme === 'undefined' ||
			auth.site.current.theme === null
		) {
			window.sendEvent('toast', {
				title: '糟糕！你還沒選擇一網站設計',
				message: '請利用左側選單「網站設計」選擇一網站主題',
			});
		}
	}
}

export const page = new Page();
