import Alpine from 'alpinejs';
import Controller from './controller';

import { userList, roleList, siteList, memberSave, memberEnable, memberDisable, memberGet } from '../libs/api';
import { pluck } from '../helpers/utils';

class Member extends Controller {
    filterItems = [
        {
            id: 1,
            name: '搜尋會員...',
            type: 'search',
            default: '',
            col: 'name',
        },
        {
            id: 2,
            name: '角色',
            type: 'select',
            col: 'role',
            default: 'all',
            items: [
                { id: 'all', name: '所有角色' },
                { id: 1, name: 'Super Administrator' },
                { id: 2, name: 'Administrator' },
                { id: 3, name: 'Editor' },
                { id: 4, name: 'Author' },
                { id: 5, name: 'Contributor' },
                { id: 6, name: 'Member' },
            ],
        },
        {
            id: 3,
            name: '狀態',
            type: 'select',
            col: 'status',
            default: 'all',
            items: [
                { id: 'all', name: '所有狀態' },
                { id: 1, name: '正常' },
                { id: 0, name: '失效' },
            ],
        },
    ];

    shortcut() {
        const modal = Alpine.store('modal');

        return {
            data: { id: null },

            async do(action, id, force = false, data = {}) {
                this.data.id = id === null ? this.data.id : +id;

                if (force === false) {
                    return await this[`${action}`](this.data.id, force, data);
                }

                if (this.data.id === null) {
                    const router = Alpine.store('router');
                    return router.go('/member');
                }

                const info = Alpine.store('info');

                try {
                    await this[`${action}`](this.data.id, force, data);

                    modal.close();
                    info.list.get();
                } catch (error) {
                    console.log(error, '<<<<----');
                }
            },

            async enable(id, force, attributes) {
                if (force === false) {
                    return sendEvent('modal', {
                        html: '/pages/member/confirm-enable.html',
                    });
                }

                const { code, data } = await memberEnable({ id });

                let notify = { title: `帳號啟動失敗` };

                if (code === 1) {
                    notify = {
                        title: `帳號啟動成功`,
                        icon: 'bi-file-earmark-check',
                    };
                }

                window.sendEvent('toast', notify);
            },

            async disable(id, force, attributes) {
                if (force === false) {
                    return sendEvent('modal', {
                        html: '/pages/member/confirm-disable.html',
                    });
                }

                const { code, data } = await memberDisable({ id });

                let notify = { title: `帳號停用失敗` };

                if (code === 1) {
                    notify = {
                        title: `帳號停用成功`,
                        icon: 'bi-file-earmark-check',
                    };
                }

                window.sendEvent('toast', notify);
            },
        };
    }

    data() {
        const shortcut = this.shortcut();

        return {
            title: '會員管理',

            init() {
                this.id = +this.id;
                this.editor.data.id = this.id;
            },

            list: {
                loaded: false,
                data: {},
                async get() {
                    const query = Alpine.store('query');
                    const parent = Alpine.store('info');

                    this.loaded = false;

                    const { code, data } = await userList(query.fetch());
                    this.loaded = true;

                    if (data) {
                        this.data = data;

                        const meta = {
                            pos: +data.pos || 0,
                            count: +data.count || 0,
                            total: +data.total || 0,
                        };

                        query.midify(meta);
                        parent.paginate.init(meta);
                    } else {
                        this.data = {};
                    }
                },
            },

            editor: {
                loaded: false,
                own: false,
                data: {
                    nickname: '',
                    jobtitle: '',
                    info: '',
                    mobile: '',
                    password: '',
                    account: '',
                    enable_account: true,
                    role: [],
                    website: [],
                },

                async init() {
                    const auth = Alpine.store('auth');
                    console.log('member::editor::init', { id: this.data.id });

                    if (this.data.id === 0) {
                        this.loaded = true;
                    } else if (this.data.id === +auth.user.id) {
                        this.own = true;
                    } else {
                        await this.load(this.data.id);
                    }

                    await this.role.get();
                    await this.website.get();
                },

                async load(id) {
                    const { code, data } = await memberGet({
                        id: +this.data.id,
                    });

                    const role = pluck(data.role, ['id']);
                    const site = pluck(data.site, ['id']);

                    console.log(role, site, '<-------');

                    this.data = { ...data, role, website: site };
                    this.loaded = true;

                    console.log(data, '{{--');
                },

                async submit() {
                    const { code } = await memberSave({
                        ...this.data,
                        id: +this.data.id,
                    });

                    if (code === 1) {
                        const router = Alpine.store('router');
                        router.go(`member`);
                    }

                    return false;
                },

                role: {
                    data: null,
                    loaded: false,
                    async get() {
                        const {
                            code,
                            data: { rows: roles },
                        } = await roleList();

                        this.loaded = true;
                        this.data = roles;
                    },
                    set(selector) {
                        const tidied = this.data.map((role) => {
                            return { id: role.id, title: role.name };
                        });

                        selector.addOptions(tidied, false);

                        const info = Alpine.store('info');
                        const roles = (info.editor.data.role ?? []).length === 0 ? [] : info.editor.data.role ?? [];

                        roles.map((role) => selector.setValue(`${role}`, true));
                    },
                },

                website: {
                    data: null,
                    loaded: false,
                    async get() {
                        const {
                            code,
                            data: { rows: site },
                        } = await siteList();

                        this.loaded = true;
                        this.data = site;
                    },
                    set(selector) {
                        const tidied = this.data.map((site) => {
                            return { id: site.id, title: site.name };
                        });

                        selector.addOptions(tidied, false);

                        const info = Alpine.store('info');
                        const sites = (info.editor.data.website ?? []).length === 0 ? [] : info.editor.data.website ?? [];

                        sites.map((site) => selector.setValue(`${site}`, true));
                    },
                },
            },

            shortcut,
        };
    }
}

export const member = new Member();
