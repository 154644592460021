import Alpine from 'alpinejs';
import Controller from './controller';

class Staff extends Controller {
	data() {
		const auth = Alpine.store('auth');
		return {
			permissionsSet: [],
			title: '管理員',
			basePermissions: auth?.user.base_permissions,
			staff: {
				sites: [],
				places: [],
			},
			all: this.all,
			get: this.get,
			save: this.save,
			sort: this.all,
		};
	}

	async all() {
		const query = Alpine.store('query');

		const result = await Alpine.store('api').getClient().post('staff/list', { query: query.fetch() });
		this.staff = result.data?.rows;

		const meta = {
			pos: +result.data.pos,
			count: +result.data.count,
			total: +result.data.total,
		};

		query.midify(meta);
		this.paginate.init(meta);
	}

	async get() {
		const info = Alpine.store('info');

		if (info.id == 0) {
			return false;
		}

		const result = await Alpine.store('api').getClient().post('staff/get', { id: info.id });
		this.staff = result.data;
		this.title += ' - ' + this.staff.account;
	}

	async save() {
		const info = Alpine.store('info');
		const result = await Alpine.store('api')
			.getClient()
			.post('staff/save', { ...info.staff, permissions: info.permissionsSet });

		console.log(result, '<--');

		if ('code' in result && result.code === 1) {
			Alpine.store('router').go('staff');

			return;
		}
	}
}

export const staff = new Staff();
