import Alpine from 'alpinejs';
import Controller from './controller';

class Profile extends Controller {
    data() {
        return {
            title: '個人資訊',
        };
    }
}

export const profile = new Profile();
