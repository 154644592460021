import {
	memberStatus,
	// memberWriterRegister,
	memberWriterLogin,
	memberLogout,
	memberWriterLoginByToken,
} from '../libs/api';

export const registerAuthService = (alpine) => {
	alpine.magic('user', () => {
		const auth = alpine.store('auth');
		return auth.user;
	});

	alpine.magic('site', () => {
		const auth = alpine.store('auth');
		return auth.site;
	});

	alpine.store('auth', {
		isAuthenticated: false,

		user: {},
		site: {},
		role: {},
		// verify: { token: null, ts: 0 },

		approve(meta) {
			// console.log('auth::service::approve', meta);
			this.isAuthenticated = meta.isLogined === 1;
			this.user = meta.user;
			this.site = meta.site;
			this.role = meta.role;

			if (meta.remeber) {
				let saved = localStorage.getItem('rmbr');
				saved = saved === null ? '{}' : saved;
				saved = JSON.parse(saved);

				saved[meta.remeber.site] = meta.remeber;

				localStorage.setItem('rmbr', JSON.stringify(saved));
			}
		},

		reject() {
			this.isAuthenticated = false;
		},

		isWhitelisted(match) {
			return (
				match.url === 'auth/login' ||
				match.url === 'auth/logout' ||
				match.url === 'auth/register' ||
				match.url === 'auth/forgot' ||
				match.url === 'auth/forgot/success'
			);
		},

		async status(match, force = false) {
			const that = this;
			// console.log('auth::service::status', match, force);

			if (this.isAuthenticated && force === false) {
				return false;
			}

			const { code, data: status } = await memberStatus();
			if (!!status.isLogined === false) {
				// console.log('auth::service::status::redirectToLogin');
				// if (that.isWhitelisted(match) === false) {
				// 	router.go('/auth/login', { silent: true });
				// }

				return false;
			}

			this.approve(status);

			const router = alpine.store('router');

			router.go(match.url === 'auth/login' ? '' : match.url);
		},

		async signIn(data, type = 'user') {
			console.log('AuthService::signin');
			let { code, data: status } = await memberWriterLogin(data, type);
			if (code === 1) {
				this.approve(status);
				this.status({ url: 'auth/login' }, true);

				return true;
			}

			return this.reject();
		},

		async signInByToken(data, type = 'user') {
			let { code, data: status } = type === 'writer' ? await memberWriterLoginByToken(data) : {};
			if (code === 1) {
				this.approve(status);
				this.status({ url: 'auth/login' }, true);

				return true;
			}

			return this.reject();
		},

		async signOut() {
			let { code } = await memberLogout();
			if (code === 1) {
				this.reject();
				alpine.store('router').go('/auth/logout');
			}
		},
	});
};
