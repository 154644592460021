import urlcat from 'urlcat';
import pinyin from 'tiny-pinyin';

export const groupBy = function (arr, f) {
	return arr.reduce((out, val) => {
		let by = typeof f === 'function' ? '' + f(val) : val[f];
		(out[by] = out[by] || []).push(val);
		return out;
	}, {});
};

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const compareArrays = (a, b) => {
	return JSON.stringify(a) === JSON.stringify(b);
};

export const jsonParse = (string, def = {}) => {
	let data = null;
	try {
		data = JSON.parse(string);
	} catch (e) {
		data = def;
	}

	return data;
};

export const _uuid = () => {
	let d = Date.now();
	if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
		d += performance.now();
	}
	return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export const onlyLetters = (str) => {
	return str.replace(/^[\s\uFEFF\xA0\u3000]+|[\s\uFEFF\xA0\u3000]+$/g, '').replace(/[！-～;；：，。？““"〃]+/g, '');
};

export const url = (baseUrl, pathTemplate = '', params = {}) => {
	return urlcat(baseUrl, pathTemplate, params);
};

export const slug = (text) =>
	text
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[-]+/g, '-')
		.replace(/[_]+/g, '-')
		.replace(/[^\w-]+/g, '');

export const toPinyin = (text) => {
	if (pinyin.isSupported() === false) {
		console.log('Does not support automatic generation of slugs.');
		return text;
	}

	const structs = pinyin.parse(text);
	let slug = '';

	structs.map((struct) => {
		const { type, target } = struct;
		const letter = `${target.toLowerCase()}`;
		const last = slug.at(-1);

		if (type === 2) {
			slug = `${slug}${letter}-`;
		} else if (type === 1) {
			if (/^[a-z0-9]$/.test(letter)) {
				slug = `${slug}${letter}`;
			} else if (/^[a-z0-9]$/.test(last)) {
				slug = `${slug}-`;
			}
		}
	});

	return slug.trim().replace(/-$/, '');
};

export const pick = (obj, keys) => Object.fromEntries(keys.filter((key) => key in obj).map((key) => [key, obj[key]]));

export const pluck = (array, key) => array.map((o) => o[key]);

export const get = (obj, path) => {
	let paths = path.split('.'),
		current = obj,
		i;

	for (i = 0; i < paths.length; ++i) {
		if (current[paths[i]] == undefined) {
			return undefined;
		} else {
			current = current[paths[i]];
		}
	}
	return current;
};

export const buttonFlow = (btn) => {
	if (btn instanceof $ === false) {
		return false;
	}

	const loadingIcon = `<span class="spinner-border spinner-border-sm me-2 js-spinner" role="status" aria-hidden="true"></span>`;
	const finishIcon = `<span class="js-finish"><i class="bi bi-check-circle me-2 "></i></span>`;

	let button = btn;
	if (button.is('button') === false) {
		button = button.closest('button');
	}

	console.log('utils::button::flow', btn, button);

	return {
		lock() {
			button.prop('disabled', true);
			button.find('i').show();
			button.find('.js-finish,.js-spinner').remove();
		},
		loading() {
			button.find('i').hide();
			button.prop('disabled', true);
			button.prepend(loadingIcon);
		},
		done(force = true) {
			const that = this;

			button.find('.js-spinner').remove();
			button.prepend(finishIcon);

			sleep(2000).then(() => {
				force && button.prop('disabled', false);
				button.find('.js-finish').remove();
				button.find('i').show();
			});
		},
		reset() {
			button.prop('disabled', false);
			button.find('.js-finish,.js-spinner').remove();
			button.find('i').show();
		},
	};
};

const templates = new Map();
const injectedClass = 'inited';

export const routeHandler = (navigo, template, taElementSelector, controller, params) =>
	new Promise((resolve) => {
		const taElement = document.querySelector(taElementSelector);
		// const isWrapper = taElement.id === 'wrapper';

		if (taElement) {
			const eleObserver = new MutationObserver((mutations) => {
				mutations.forEach((_mutation) => {
					if (controller) {
						eleObserver.disconnect();
						navigo.updatePageLinks();
						controller.run(params);
						resolve();
					}
				});
			});

			// TODO: optimizing screen flickering issues.
			// if (isWrapper && taElement.classList.contains(injectedClass)) {
			// 	resolve();
			// 	return false;
			// }

			sleep(6).then(() => {
				taElement.innerHTML = '';

				eleObserver.observe(taElement, { childList: true });
				controller.preload(params);

				const content = templates.get(template);
				if (content) {
					taElement.innerHTML = content;
				} else {
					fetch(template).then((response) =>
						response.text().then((content) => {
							templates.set(template, content);
							taElement.innerHTML = content;
						})
					);
				}

				// if (isWrapper) {
				// 	taElement.classList.add(injectedClass);
				// }
			});
		}
	});
