import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';
import TimeAgo from '@marcreichel/alpine-timeago';
import persist from '@alpinejs/persist';
import { zhTW } from 'date-fns/locale';
// import storage from 'alpinejs-persist-extended';
import Tooltip from '@ryangjchandler/alpine-tooltip';
import validation from './libs/validation';
import HawkCatcher from '@hawk.so/javascript';
import component from 'alpinejs-component';

import '../styles/style.scss';

import { initRouter } from './router/index.js';

// import { registerApiService } from './services/apiService.js';
import { registerAuthService } from './services/authService.js';
// import { registerVerifyService } from './services/verifyService.js';

// import { registerSelectorComponent } from './components/selector.js';

import { registerSubmitHelper } from './helpers/submit.js';
import { registerMenuHelper } from './helpers/menu.js';
import { registerIconHelper } from './helpers/icon.js';
import { registerLinkHelper } from './helpers/link.js';
import { registerSelectHelper } from './helpers/select.js';
import { registerSidebarHelper } from './helpers/sidebar.js';
import { registerCheckboxHelper } from './helpers/checkbox.js';
import { registerDropdownHelper } from './helpers/dropdown.js';
import { registerCodeEditorHelper } from './helpers/codeEditor.js';
import { registerUploadHelper } from './helpers/upload.js';
import { registerEditorHelper } from './helpers/editor.js';
// import { registerSortHelper } from './helpers/sort.js';
import { registerQueryHelper } from './helpers/query.js';
import { registerPaginateHelper } from './helpers/paginate.js';
import { registerImageHelper } from './helpers/image.js';
import { registerFilterHelper } from './helpers/filter.js';
import eventSender from './helpers/event';
import modal from './helpers/modal';
import { registerMagic } from './helpers/magic';
import { registerTappayHelper } from './helpers/tappay';
import dropzone from './libs/dropzone';
import selector from './helpers/selector';
import autoHeight from './helpers/autoHeight.js';
import swiper from './libs/swiper.js';
import textarea from './libs/textarea.js';
import sortable from './libs/sortable.js';
import sort from './libs/sort.js';
import tippy from './libs/tippy.js';
import intlTelInput from './libs/intlTelInput.js';

// registerApiService(Alpine);
registerAuthService(Alpine);
// registerVerifyService(Alpine);

registerSubmitHelper(Alpine);
registerMenuHelper(Alpine);
registerIconHelper(Alpine);
registerLinkHelper(Alpine);
registerSelectHelper(Alpine);
registerSidebarHelper(Alpine);
registerCheckboxHelper(Alpine);
registerDropdownHelper(Alpine);
registerCodeEditorHelper(Alpine);
registerUploadHelper(Alpine);
registerEditorHelper(Alpine);
registerMagic(Alpine);
// registerSortHelper(Alpine);
registerQueryHelper(Alpine);
registerPaginateHelper(Alpine);
registerImageHelper(Alpine);
registerFilterHelper(Alpine);
registerTappayHelper(Alpine);

initRouter(Alpine);

Alpine.store('modal', modal);

Alpine.plugin(sort);
Alpine.plugin(sortable);
Alpine.plugin(textarea);
Alpine.plugin(swiper);
Alpine.plugin(autoHeight);
Alpine.plugin(selector);
Alpine.plugin(dropzone);
Alpine.plugin(validation);
Alpine.plugin(Tooltip);
Alpine.plugin(mask);
Alpine.plugin(persist);
Alpine.plugin(component);
Alpine.plugin(tippy);
Alpine.plugin(intlTelInput);

// Alpine.plugin(storage);
Alpine.plugin(
	TimeAgo.configure({
		locale: zhTW,
	})
);

// window.hawk = new HawkCatcher({ token: import.meta.env.VITE_HAWK_KEY });

window.sendEvent = eventSender;
window.modal = [];
window.modelClose = () => {
	const modal = window.modal.pop();
	modal.close();
};

window.Alpine = Alpine;
window.addEventListener('error', (event) => {
	console.error(`${event.type}: ${event.message}\n`, event);
});

queueMicrotask(() => {
	Alpine.start();
});
