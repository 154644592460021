import { DateTime } from 'luxon';
import { url, slug, get } from './utils';

export const registerMagic = (alpine) => {
	const site_url = (str) => {
		const site = alpine.store('auth')?.site?.current?.domain ?? '';
		return url(`https://${site}`, str);
	};

	window.site_url = site_url;

	alpine.magic('site_url', () => {
		return (str) => site_url(str);
	});

	alpine.magic('asset', () => {
		return (asset) => url(import.meta.env.VITE_CDN_BASE, asset);
	});

	alpine.magic('clearBreaks', () => {
		return (str) => {
			if (typeof str !== 'string') {
				return '';
			}

			return `${str.replace(/(\r\n|\n|\r)/gm, '')}`;
		};
	});

	alpine.magic('hideEmail', () => {
		return (email) => {
			return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
				for (let i = 0; i < gp3.length; i++) {
					gp2 += '*';
				}
				return gp2;
			});
		};
	});

	alpine.magic('slug', () => {
		return (text) => slug(text);
	});

	alpine.magic('url', () => {
		return (base, path, params = {}) => {
			return url(base, path, params);
		};
	});

	alpine.magic('nextMonth', () => {
		return () => {
			return DateTime.now().plus({ months: 1, days: 1 }).toFormat('yyyy/MM/dd（ccc）', { locale: 'zh-TW' });
		};
	});

	alpine.magic('now', () => {
		return (fmt = 'yyyy-MM-dd HH:mm:ss') => {
			return DateTime.now().toFormat(fmt);
		};
	});

	alpine.magic('tsFormat', () => {
		return (ts, fmt = 'YYYY-MM-DD') => {
			return DateTime.fromSQL(ts).toFormat(fmt);
		};
	});

	alpine.magic('cond', () => {
		return (value, def) => {
			console.log(
				'magic::cond',
				value,
				def,
				'result',
				typeof value === 'undefined',
				typeof value === 'undefined' ? def : true
			);
			return typeof value === 'undefined' ? def : true;
		};
	});

	alpine.magic('get', () => {
		return (obj, path = '') => {
			console.log('magic::get', obj, path, 'result', get(obj, path));
			return get(obj, path);
		};
	});

	alpine.magic('paginate', () => {
		return (current, page2Show, totalPage) => {
			let pages = [];

			const start = Math.max(+current - Math.floor(+page2Show / 2), 1);
			const end = Math.min(start + +page2Show - 1, +totalPage);

			if (start > 1) {
				pages.push(1);
				if (start > 2) {
					pages.push('...');
				}
			}

			for (let i = start; i <= end; i++) {
				pages.push(i);
			}

			if (end < +totalPage) {
				if (end < +totalPage - 1) {
					pages.push('...');
				}
				pages.push(+totalPage);
			}

			return pages;
		};
	});
};

/*
function convertToSlug(Text) {
  return Text.toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
}

*/
