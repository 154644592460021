import intlTelInput from 'intl-tel-input';

export default function (Alpine) {
    Alpine.directive('intltelinput', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const ta = $(el);
        const modelExpression = ta.attr('x-model');
        // console.log('intlTelInput::init', el);
        const input = intlTelInput(el, {
            // localizedCountries: {
            //     tw: '台灣',
            // },
            placeholderNumberType: 'MOBILE',
            // allowDropdown: false,
            autoInsertDialCode: true,

            onlyCountries: ['tw'],
            utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js',
        });

        ta.on('input', () => {
            evaluate(`${expression} = '${input.getNumber()}'`);
        });

        effect(() => {
            const value = evaluate(expression);
            if ($.trim(value)) {
                input.setNumber(value);
            }
        });

        cleanup(() => {
            // console.log('intltelinput::destory');
            input.destroy();
        });
    });
}
