const filterTemplate = `
<template x-for="rule in filter.items">
    <div class="col-auto" x-data="{selected: $get(filter.current, rule.col + '.value') || rule.default}">
        <template x-if="rule.type === 'select'">
            <select class="form-select" @change="filter.query(rule.id, $event.target.value)">
                <template x-for="option in rule.items">
                    <option
                        :value="option.id"
                        :selected="+selected === +option.id"
                        x-text="option.name"
                    ></option>
                </template>
            </select>
        </template>
        <template x-if="rule.type === 'search'">
            <div class="filter--text">
                <input type="text" class="form-control" :placeholder="rule.name" @change="filter.query(rule.id, $event.target.value)" :value="selected">
            </div>
        </template>
    </div>
</template>
`;

export const registerFilterHelper = (alpine) => {
    alpine.directive(
        'filter',
        (el, { value, modifiers, expression }, { Alpine, evaluate, evaluateLater, effect, cleanup }) => {
            const ta = $(el);
            ta.toggleClass('row filter', true);
            el.innerHTML = filterTemplate;
        }
    );
};
