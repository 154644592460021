import axios from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';

const jar = new CookieJar();

let headers = { 'Content-Type': 'application/json' };
axios.defaults.withCredentials = true;
axios.defaults.timeout = 3000;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-Requested-By'] = `${window.location.hostname}`;

let instance = wrapper(
    axios.create({
        baseURL: import.meta.env.VITE_API_BASE,
        jar: jar,
    })
);

// axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         console.log('error::', error, '<--');
//         window.sendEvent('toast', {
//             title: '糟糕！網路發生異常',
//             message: '網頁載入速度緩慢或完全無法開啟',
//         });
//         Promise.reject(error.response);
//     }
// );

const errorTranx = {
    2001: '找不到使用者。',
    2006: '登錄嘗試次數過多。請稍後再試。',
    2007: '無法重複使用舊密碼。',
};

const _request = function (path, params = {}, method = 'post') {
    return new Promise((resolve, reject) => {
        console.group('api::post', path, params);
        instance
            .post(path, params, {
                headers: headers,
                timeout: 1000 * 10,
            })
            .then((res) => {
                if (res.data.code !== 1) {
                    window.sendEvent('toast', {
                        message: res.data.msg,
                    });
                }
                console.table(res.data);
                resolve(res.data);
            })
            .catch((error) => {
                const { status, data: response } = error.response;

                if (status === 500) {
                    window.sendEvent('toast', {
                        title: '糟糕！網路發生異常',
                        message: '網頁載入速度緩慢或完全無法開啟',
                    });
                } else {
                    window.sendEvent('toast', {
                        title: errorTranx[response.code] || response.msg, // `錯誤 ${response.code}`,
                        // message: ,
                    });
                }

                console.error('Api::post error', response);

                reject(error);
            })
            .finally(() => {
                console.groupEnd('api::post', path);
            });
    });
};

const _upload = function (path, params = {}) {
    const formHeaders = Object.assign({}, JSON.parse(JSON.stringify(headers))); // deep copy
    Object.assign(formHeaders, { 'Content-Type': 'multipart/form-data' }); // overwrite Content-Type

    const formData = new FormData();
    Object.keys(params).forEach((key, value) => {
        formData.append(key, params[key]);
    });

    return new Promise((resolve, reject) => {
        console.group('api::upload', path, params);
        instance
            .post(path, formData, {
                headers: formHeaders,
                timeout: 1000 * 30,
            })
            .then((res) => {
                if (res.data.code !== 1) {
                    window.sendEvent('toast', {
                        message: res.data.msg,
                    });
                }
                console.table(res.data);
                resolve(res.data);
            })
            .catch((error) => {
                const { status, data: response } = error.response;

                if (status === 500) {
                    window.sendEvent('toast', {
                        title: '糟糕！網路發生異常',
                        message: '網頁載入速度緩慢或完全無法開啟',
                    });
                } else {
                    window.sendEvent('toast', {
                        title: errorTranx[response.code] || response.msg, // `錯誤 ${response.code}`,
                        // message: ,
                    });
                }

                console.error('Api::post error', response);

                reject(error);
            })
            .finally(() => {
                console.groupEnd('api::post', path);
            });
    });
};

const role = 'backend';

export const memberStatus = () => _request(`/api/member/status?t=${role}`);
export const memberWriterLogin = (data, type) => _request(`/api/member/login?t=${type}`, data);
export const memberWriterLoginByToken = (data) => _request(`/api/member/token_login?t=${role}`, data);
export const memberLogout = (data) => _request('/api/member/logout', data);
export const memberForgot = (data) => _request('/api/member/forgot', data);
export const memberReset = (data) => _request('/api/member/reset', data);

export const memberGet = (data) => _request('/api/member/get', data);
export const memberSave = (data) => _request('/api/member/save', data);
export const memberEnable = (data) => _request('/api/member/enable', data);
export const memberDisable = (data) => _request('/api/member/disable', data);

export const roleList = (data) => _request('/api/role/list', data);

export const siteList = (data) => _request('/api/site/list', data);

// export const pressList = (type, query) => _request(`/api/press/list?t=${type}`, { query });
// export const pressSave = (data, type) => _request(`/api/press/save?t=${type}`, data);
// export const pressGet = (data, type) => _request(`/api/press/get?t=${type}`, data);

export const userList = (query) => _request(`/api/member/list`, { query });

export const siteAvailableCheck = (slug) => _request('/api/site/available', { slug });

export const elementImageUpload = (data) => _upload('/api/element/upload/image', data);
