import Alpine from 'alpinejs';
import Controller from './controller';

class Setting extends Controller {
    data() {
        return {
            title: '網站管理',
        };
    }
}

export const setting = new Setting();
