import Swiper, { Navigation, Pagination } from 'swiper';

export default function (Alpine) {
    Alpine.directive('swiper', (el, { expression, value }, { evaluateLater, evaluate, effect, cleanup }) => {
        const selected = el.dataset.selected;

        const selectedCallback = (idx) => {
            if (selected === '') {
                return false;
            }

            const callback = evaluate(selected);

            if (typeof callback === 'function') {
                callback(idx);
            }
        };

        const swiper = new Swiper(el, {
            modules: [Navigation, Pagination],

            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,

            mousewheel: {
                enabled: true,
                forceToAxis: true,
            },

            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                init: (sw) => selectedCallback(sw.activeIndex),
            },
        });

        swiper.on('slideChange', (sw) => selectedCallback(sw.activeIndex));

        cleanup(() => {
            swiper.detachEvents();
            swiper.destroy(true);
        });
    });
}
