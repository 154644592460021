import EditorJS from '@editorjs/editorjs';

import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import AnchorTune from '../libs/editor.js/editorjs-anchor';
import Album from '../libs/editor.js/album/album';
// import AnchorBlockTune from '../libs/editor.js/editorjs-anchor.js';
// import FontColor from '../libs/editor.js/editorjs-text-color-plugin.js';
import ImageTool from '@editorjs/image';
import TextVariantTune from '@editorjs/text-variant-tune';
import Table from '@editorjs/table';
import Undo from 'editorjs-undo';
import Embed from '@editorjs/embed';

import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';

import { compareArrays, jsonParse, sleep, url } from './utils';
import { elementImageUpload } from '../libs/api';

const analysis = function (blocks) {
	let paragraph = '';
	let imageCount = 0;
	const avgReadingSpeed = 300;

	blocks.map((block) => {
		if (block.type === 'paragraph') {
			const text = block.data.text.replace(/(<([^>]+)>)/gi, '').replace(/&(nbsp|amp|quot|lt|gt);/g, '');

			paragraph = paragraph.concat('', text);
		} else if (block.type === 'image') {
			imageCount += 1;
		} else if (block.type === 'album') {
			imageCount += block.data.urls.length ?? 0;
		}
	});

	const chineseCount = paragraph.match(/[\u4e00-\u9fa5]/g) ? paragraph.match(/[\u4e00-\u9fa5]/g).length : 0;
	const englishCount = paragraph.match(/[a-zA-Z]/g) ? paragraph.match(/[a-zA-Z]/g).length : 0;
	const numberCount = paragraph.match(/\d/g) ? paragraph.match(/\d/g).length : 0;

	const totalCount = chineseCount + englishCount + numberCount + imageCount;
	const readingTime = totalCount / avgReadingSpeed;

	return {
		text: paragraph,
		total: totalCount,
		readingTime: Math.ceil(readingTime),
		detail: {
			chinese: chineseCount,
			english: englishCount,
			number: numberCount,
			image: imageCount,
		},
	};
};

export const registerEditorHelper = (alpine) => {
	const configs = {
		// minHeight: 300,
		placeholder: '在這邊輸入內容',
		tools: {
			header: {
				class: Header,
				inlineToolbar: ['link' /*'fontColor'*/],
				config: {
					placeholder: '輸入標題',
					levels: [3, 4, 5, 6],
					defaultLevel: 3,
				},
				shortcut: 'CMD+SHIFT+H',
			},
			image: {
				class: ImageTool,
				inlineToolbar: ['link'],
				config: {
					field: 'file',
					types: 'image/png, image/jpg',
					uploader: {
						async uploadByFile(file) {
							// const url = 'element/upload/image';

							const {
								code,
								data: { path },
							} = await elementImageUpload({ file: file });

							return {
								success: 1,
								file: {
									url: url(import.meta.env.VITE_CDN_BASE, path),
								},
							};

							// const api = alpine.store('api').getClient();
							// return api
							// 	.postForm(url, { file: file })
							// 	.then((result) => {
							// 		return {
							// 			success: 1,
							// 			file: {
							// 				url: window.site_url(result.data.path, 'cdn'),
							// 			},
							// 		};
							// 	})
							// 	.catch((error) => console.log('Editor::uploadByFile', error));
						},
						uploadByUrl(url) {
							return fetch(url)
								.then((response) => response.blob())
								.then(async (imageBlob) => {
									const filename =
										'f' +
										Math.floor((1 + Math.random()) * 0x10000)
											.toString(16)
											.substring(1);
									const file = new File(
										[imageBlob],
										`${filename}.${imageBlob.type.split('/').slice(-1).pop()}`,
										{ type: imageBlob.type }
									);

									return this.uploadByFile(file);
								});
						},
					},
					// endpoints: {},
				},
			},
			album: {
				class: Album,
				inlineToolbar: true,
				config: {
					async uploader(file) {
						const {
							code,
							data: { path },
						} = await elementImageUpload({ file: file });

						return url(import.meta.env.VITE_CDN_BASE, path);
						// const url = 'element/upload/image';

						// const api = alpine.store('api').getClient();
						// return api
						// 	.postForm(url, { file: file })
						// 	.then((result) => {
						// 		return window.site_url(result.data.path, 'cdn');
						// 	})
						// 	.catch((error) => console.log('Editor::uploadByFile', error));
					},
				},
			},
			list: {
				class: List,
				inlineToolbar: true,
				shortcut: 'CMD+SHIFT+L',
			},

			// fontColor: {
			//     class: FontColor,
			//     config: {
			//         colors: ['#6aa2b9', '#28a745', '#ffc107', '#dc3545', '#b38a5f'],
			//         columnsCount: 4,
			//     },
			// },
			Marker: {
				class: Marker,
				shortcut: 'CMD+SHIFT+M',
			},
			paragraph: {
				inlineToolbar: true,
			},
			table: {
				class: Table,
				inlineToolbar: true,
				config: {
					rows: 2,
					cols: 3,
				},
				shortcut: 'CMD+ALT+T',
			},
			warning: {
				class: Warning,
				inlineToolbar: true,
				config: {
					titlePlaceholder: '標題',
					messagePlaceholder: '訊息',
				},
			},

			embed: {
				class: Embed,
				config: {
					services: {
						youtube: {
							regex: /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
							embedUrl: 'https://www.youtube.com/embed/<%= remote_id %>',
							html: '<iframe height="315" height="315" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
							id: ([id, params]) => {
								if (!params && id) {
									return id;
								}

								const paramsMap = {
									start: 'start',
									end: 'end',
									t: 'start',
									// eslint-disable-next-line camelcase
									time_continue: 'start',
									list: 'list',
								};

								params = params
									.slice(1)
									.split('&')
									.map((param) => {
										const [name, value] = param.split('=');

										if (!id && name === 'v') {
											id = value;
											return null;
										}

										if (!paramsMap[name]) {
											return null;
										}

										return `${paramsMap[name]}=${value}`;
									})
									.filter((param) => !!param);

								return id + '?' + params.join('&');
							},
						},
						soundcloud: {
							regex: /https?:\/\/(?:w\.|www\.|)(?:soundcloud\.com\/)(?:(?:player\/\?url=https\%3A\/\/api.soundcloud.com\/tracks\/)|)(((\w|-)[^A-z]{7})|([A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*(?!\/sets(?:\/|$))(?:\/[A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*){1,2}))/,
							embedUrl:
								'https://w.soundcloud.com/player/?url=https://soundcloud.com/<%= remote_id %>&amp;color=%23463d2e&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true',
							html: '<iframe frameborder="no" height="166" width="100%" scrolling="no"></iframe>',
							id: ([id, params]) => {
								return id;
							},
						},
					},
				},
			},

			// tmplCode: tmplCode,
			quote: {
				class: Quote,
				inlineToolbar: true,
				shortcut: 'CMD+SHIFT+O',
				config: {
					quotePlaceholder: '輸入引言',
					captionPlaceholder: '輸入作者',
				},
			},

			delimiter: Delimiter,

			anchorTune: AnchorTune,
			textVariant: TextVariantTune,
		},
	};

	let editor = null;
	let execWaiting = null;

	alpine.directive('editor', async (el, { expression }, { evaluate, evaluateLater, effect, cleanup }) => {
		const getContent = evaluateLater(expression);
		const onChange = evaluate(el.dataset.onchange);
		const loadedSignal = el.dataset.loaded;
		let loaded = false;
		let readonly = false;

		const execAnalysis = function (content) {
			if (el.dataset.analysis) {
				const meta = analysis(content.blocks);
				evaluate(`${el.dataset.analysis} = ${JSON.stringify(meta)}`);
			}
		};

		const execSave = function (api) {
			api.saver.save().then((output) => {
				const originalContentString = evaluate(expression);
				const originalContent = jsonParse(originalContentString);
				const isDifferent = compareArrays(originalContent.blocks, output.blocks);

				const spell = `${expression} = ${JSON.stringify(output)}`;
				evaluate(spell);

				execAnalysis(output);

				if (isDifferent === false && typeof onChange === 'function') {
					onChange(originalContent, output);
				}
			});
		};

		const setContent = function () {
			getContent((content) => {
				try {
					// console.log('editor::set::content', content, evaluate(loadedSignal));
					if (typeof content === 'undefined' || typeof content.blocks === 'undefined') {
						return true;
					}

					// let content = JSON.parse(content);

					if (content?.blocks?.length === 0) {
						return false;
					}

					editor.blocks.render(content).then(() => {
						const undo = new Undo({ editor });
						undo.initialize(content);

						execAnalysis(content);
					});
				} catch (e) {
					console.error(e, 'error');
					editor.clear();
				}
			});
		};

		// await sleep(450);

		editor = new EditorJS({
			holder: el,
			onReady: () => {
				effect(() => {
					console.log('editor::ready::effect');
					let current = false;
					if (typeof loadedSignal !== 'undefined') {
						current = evaluate(loadedSignal);
					} else {
						current = true;
					}

					if (loaded === false && current === true) {
						setContent();
						loaded = true;
					}
				});
			},
			onChange: (api, event) => {
				if (readonly) return;

				clearTimeout(execWaiting);
				execWaiting = setTimeout(() => execSave(api), 50);
			},
			...configs,
		});

		$(window)
			.on('editor::focus', () => {
				editor.focus();
			})
			.on('editor::set::readonly', () => {
				readonly = true;
				editor.readOnly.toggle(true);
			})
			.on('editor::set::content', async (e) => {
				editor.clear();

				await sleep(10);

				const {
					originalEvent: {
						detail: { content },
					},
				} = e;

				try {
					// const data = JSON.parse(content);
					await editor.blocks.render(content);

					const undo = new Undo({ editor });
					undo.initialize(content);

					execAnalysis(content);
				} catch (e) {
					console.error(e);
				}
			})
			.on('editor::set::content::html', async (e) => {
				editor.clear();

				await sleep(10);

				const {
					originalEvent: {
						detail: { content },
					},
				} = e;

				try {
					// const data = JSON.parse(content);
					await editor.blocks.renderFromHTML(content);
				} catch (e) {
					console.error(e);
				}
			})
			.on('editor::reset', async () => {
				editor.clear();

				await sleep(10);
				setContent();

				await sleep(10);
				editor.readOnly.toggle(false);

				readonly = false;
			});

		cleanup(() => {
			editor.destroy();

			el.remove();
			$(window).off(
				'editor::focus editor::set::readonly editor::set::content editor::set::content::html editor::reset'
			);
		});
	});

	alpine.magic('editor', (el, { Alpine }) => {
		return editor;
	});
};
