import Sortable from 'sortablejs';
import './album.css';

/**
 * Tool for creating image Blocks for Editor.js
 * Made with «Creating a Block Tool» tutorial {@link https://editorjs.io/creating-a-block-tool}
 *
 * @typedef {object} ImageToolData — Input/Output data format for our Tool
 * @property {string} url - image source URL
 * @property {string} caption - image caption
 * @property {boolean} withBorder - flag for adding a border
 * @property {boolean} withBackground - flag for adding a background
 * @property {boolean} stretched - flag for stretching image to the full width of content
 *
 * @typedef {object} ImageToolConfig
 * @property {string} placeholder — custom placeholder for URL field
 */
export default class Album {
  static get isReadOnlySupported() {
    return true;
  }
  /**
   * Our tool should be placed at the Toolbox, so describe an icon and title
   */
  static get toolbox() {
    return {
      title: 'Album',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>',
    };
  }

  /**
   * Automatic sanitize config
   * @see {@link https://editorjs.io/sanitize-saved-data}
   */
  static get sanitize() {
    return {
      urls: [],
      caption: {
        b: true,
        a: {
          href: true,
        },
        i: true,
      },
    };
  }

  /**
   * Tool class constructor
   * @param {ImageToolData} data — previously saved data
   * @param {object} api — Editor.js Core API {@link  https://editorjs.io/api}
   * @param {ImageToolConfig} config — custom config that we provide to our tool's user
   * @param {boolean} params.readOnly - read-only mode flag
   */
  constructor({ data, api, config, readOnly }) {
    this.api = api;
    this.config = config || {};
    this.data = {
      urls: data.urls || [],
      caption: data.caption || '',
      withPagination: data.withPagination !== undefined ? data.withPagination : false,
      withNavigation: data.withNavigation !== undefined ? data.withNavigation : false,
      // stretched: data.stretched !== undefined ? data.stretched : false,
    };

    this.wrapper = undefined;
    this.settings = [
      {
        name: 'withPagination',
        text: '顯示分頁',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>`,
      },
      {
        name: 'withNavigation',
        text: '顯示控制項',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-code"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>`,
      },
      // {
      //   name: 'withNavigation',
      //   icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.043 8.265l3.183-3.183h-2.924L4.75 10.636v2.923l4.15-4.15v2.351l-2.158 2.159H8.9v2.137H4.7c-1.215 0-2.2-.936-2.2-2.09v-8.93c0-1.154.985-2.09 2.2-2.09h10.663l.033-.033.034.034c1.178.04 2.12.96 2.12 2.089v3.23H15.3V5.359l-2.906 2.906h-2.35zM7.951 5.082H4.75v3.201l3.201-3.2zm5.099 7.078v3.04h4.15v-3.04h-4.15zm-1.1-2.137h6.35c.635 0 1.15.489 1.15 1.092v5.13c0 .603-.515 1.092-1.15 1.092h-6.35c-.635 0-1.15-.489-1.15-1.092v-5.13c0-.603.515-1.092 1.15-1.092z"/></svg>`,
      // },
    ];

    this.readOnly = readOnly;
  }

  id() {
    return (
      'a' +
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    );
  }

  /**
   * Return a Tool's UI
   * @return {HTMLElement}
   */
  render() {
    const id = this.id();
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('album-wrapper');

    this.wrapper.innerHTML = `
      <div class="status-bar">
        <div class="status-status hidden">
          <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve"><path fill="#f1f1f4" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform  attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>
        </div>
        <div class="status-text">No image.</div>
        <label class="status-add" for="${id}">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
        </label>
        <input type="file" class="hidden" id="${id}">
      </div>
      <div class="preview-wrapper"></div>
      <input type="text" class="album-caption" placeholder="Caption" id="${id}_placeholder" value="${
      this.data.caption || ''
    }" />
    `;

    const input = this.wrapper.querySelector(`#${id}`);
    const status = this.wrapper.querySelector('.status-status');
    const preview = this.wrapper.querySelector('.preview-wrapper');

    if (!this.readOnly) {
      input.addEventListener('paste', (event) => {
        this._createImage(event.clipboardData.getData('text'));
      });

      input.addEventListener('change', (event) => {
        let files = Object.values(event.target.files);

        files.map((file) => {
          status.classList.toggle('hidden', false);
          this.config.uploader.call(null, file).then((url) => {
            this._createImage(url);

            status.classList.toggle('hidden', true);
          });
        });
      });
    }

    if (this.data && this.data.urls) {
      this.data.urls.map((url) => this._createImage(url));
    }

    const sortable = Sortable.create(preview);

    // this._sortable(preview);

    return this.wrapper;
  }

  /**
   * @private
   * Create image with caption field
   * @param {string} url — image source
   * @param {string} captionText — caption value
   */
  _createImage(url) {
    const image = document.createElement('img');
    const image_wrapper = document.createElement('div');
    const image_deleter = document.createElement('div');
    const preview = this.wrapper.querySelector('.preview-wrapper');
    const status = this.wrapper.querySelector('.status-text');

    const _displayCount = () => {
      const images = preview.querySelectorAll('.preview-image');
      const count = images.length;

      if (count === 0) {
        status.innerHTML = 'No image.';
      } else if (count === 1) {
        status.innerHTML = '1 image.';
      } else {
        status.innerHTML = `${count} images.`;
      }
    };

    image.src = url;
    image_wrapper.classList.add('preview-image');
    image_deleter.classList.add('preview-deleter');
    image_deleter.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>';

    image_deleter.addEventListener('click', (event) => {
      preview.removeChild(image_wrapper);
      _displayCount();
    });

    image_wrapper.appendChild(image);
    image_wrapper.appendChild(image_deleter);

    preview.appendChild(image_wrapper);

    _displayCount();

    this._acceptTuneView();
  }

  /**
   * Extract data from the UI
   * @param {HTMLElement} blockContent — element returned by render method
   * @return {SimpleImageData}
   */
  save(blockContent) {
    const images = blockContent.querySelectorAll('img');
    const caption = blockContent.querySelector('.album-caption');

    return Object.assign(this.data, {
      urls: Array.from(images).map((image) => image.src),
      caption: caption.value || '',
    });
  }

  /**
   * Skip empty blocks
   * @see {@link https://editorjs.io/saved-data-validation}
   * @param {ImageToolConfig} savedData
   * @return {boolean}
   */
  validate(savedData) {
    if (savedData.urls.length === 0) {
      return false;
    }

    return true;
  }

  /**
   * Making a Block settings: 'add border', 'add background', 'stretch to full width'
   * @see https://editorjs.io/making-a-block-settings — tutorial
   * @see https://editorjs.io/tools-api#rendersettings - API method description
   * @return {HTMLDivElement}
   */
  renderSettings() {
    const wrapper = document.createElement('div');

    this.settings.forEach((tune) => {
      let button = document.createElement('div');

      button.classList.add('album-tune');
      button.classList.add(this.api.styles.settingsButton);
      button.classList.toggle(this.api.styles.settingsButtonActive, this.data[tune.name]);
      button.innerHTML = `<div class="album-tune--icon">${tune.icon}</div><span class="album-tune--label">${tune.text}</span>`;
      wrapper.appendChild(button);

      button.addEventListener('click', () => {
        this._toggleTune(tune.name);
        button.classList.toggle(this.api.styles.settingsButtonActive);
      });
    });

    return wrapper;
  }

  /**
   * @private
   * Click on the Settings Button
   * @param {string} tune — tune name from this.settings
   */
  _toggleTune(tune) {
    this.data[tune] = !this.data[tune];
    this._acceptTuneView();
  }

  /**
   * Add specified class corresponds with activated tunes
   * @private
   */
  _acceptTuneView() {
    this.settings.forEach((tune) => {
      this.wrapper.classList.toggle(tune.name, !!this.data[tune.name]);

      if (tune.name === 'stretched') {
        this.api.blocks.stretchBlock(this.api.blocks.getCurrentBlockIndex(), !!this.data.stretched);
      }
    });
  }

  /**
   * Handle paste event
   * @see https://editorjs.io/tools-api#onpaste - API description
   * @param {CustomEvent }event
   */
  onPaste(event) {
    // switch (event.type) {
    //   case 'tag':
    //     const imgTag = event.detail.data;
    //     this._createImage(imgTag.src);
    //     break;
    //   case 'file':
    //     /* We need to read file here as base64 string */
    //     const file = event.detail.file;
    //     const reader = new FileReader();
    //     reader.onload = (loadEvent) => {
    //       this._createImage(loadEvent.target.result);
    //     };
    //     reader.readAsDataURL(file);
    //     break;
    //   case 'pattern':
    //     const src = event.detail.data;
    //     this._createImage(src);
    //     break;
    // }
  }
}
