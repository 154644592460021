import Alpine from 'alpinejs';
import Controller from './controller';
import menu from '../libs/menu.json';

class Plan extends Controller {
	data() {
		// const menu = Alpine.data('menu');
		// console.log(menu, '<--')
		return {
			title: '方案管理',
			plan: {
				name: '',
				price: 0,
				items: [],
			},
			items: [],
			item: {
				name: '',
				permissions: [],
			},

			menu: menu,

			all: this.all,
			sort: this.all,
			get: this.get,
			save: this.save,

			all_item: this.all_item,
			get_item: this.get_item,
			set_item: this.set_item,
		};
	}

	async all() {
		const query = Alpine.store('query');

		const result = await Alpine.store('api').getClient().post('service/list', { query: query.fetch() });
		this.plan = result.data?.rows;

		const meta = {
			pos: +result.data.pos,
			count: +result.data.count,
			total: +result.data.total,
		};

		query.midify(meta);
		this.paginate.init(meta);
	}

	async get() {
		const info = Alpine.store('info');
		if (info.id == 0) {
			return false;
		}

		const result = await Alpine.store('api').getClient().post('service/get', { id: info.id });
		this.plan = (({ id, name, price, status, item }) => ({ id, name, price, status, item }))(result.data);
		this.plan.items = this.plan.item?.map((i) => i._id);
		delete this.plan.item;
		this.title += ' - ' + this.plan.name;
	}

	async save() {
		let item = JSON.parse(JSON.stringify(this.plan));
		item.id = +this.id;

		const result = await Alpine.store('api').getClient().post('service/save', item);
		if ('code' in result && result.code === 1) {
			Alpine.store('router').go('plan');
			return;
		}
	}

	async all_item() {
		const result = await Alpine.store('api').getClient().post('service/item');
		let items = result.data?.rows;

		items = items.map((item) => {
			let structure = {};
			item.permission.sort((a, b) => {
				if (a.parent_id === b.parent_id) {
					return 0;
				}

				if (a.parent_id === null) {
					return 1;
				}
				if (b.parent_id === null) {
					return -1;
				}

				return a.parent_id < b.parent_id ? 1 : -1;
			});

			item.permission.map((permission) => {
				structure[permission.parent_id] = structure[permission.parent_id] ?? [];

				if (structure[permission._id]) {
					const parent = {
						id: permission._id,
						name: permission.name,
						parent_id: permission.parent_id,
						children: [],
					};
					parent.children = structure[permission._id];

					structure[permission.parent_id].push(parent);
					delete structure[permission._id];
				} else {
					structure[permission.parent_id].push({
						id: permission._id,
						name: permission.name,
						parent_id: permission.parent_id,
						children: [],
					});
				}
			});

			item.permission = structure['null'];

			return item;
		});

		this.items = items;
	}

	async get_item(id) {
		if (id != 0) {
			const result = await Alpine.store('api').getClient().post('service/item_get', { id: id });
			if ('code' in result && result.code === 1) {
				const data = {
					name: result.data.name,
					permissions: result.data.permission.map((permission) => permission._id),
					id: result.data._id,
				};

				const info = Alpine.store('info');
				info.item = data;
				// console.log(id, this, this.item, '<----------');

				// this.item = data;
			}
		}

		window.sendEvent('modal', {
			html: '/pages/plan/add-item.html',
			title: '新增項目',
		});
	}

	async set_item() {
		const item = JSON.parse(JSON.stringify(this.item));
		this.item = {
			name: '',
			permissions: [],
		};

		const result = await Alpine.store('api').getClient().post('service/item_save', item);
		if ('code' in result && result.code === 1) {
			this.all_item();
			return;
		}
	}
}

export const plan = new Plan();
